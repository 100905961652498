$boxSizeMargin: 1.2rem;
$smallContainerWidth: 40rem;
$containerWidth: 100rem;

@import "~./normalize";

/* height and body min-height are needed so that "aside" submenus (cart, user
 * menu) are correctly displayed when the page is shorter than the aside submenu.
 * see https://linear.app/front-commerce/issue/FC-499
 */
html {
  height: 100%;
}

body {
  margin: 0;
  min-height: 100%;
}

.ReactModal__Overlay--after-open {
  z-index: 40;
}

.ReactModal__Body--open {
  overflow: hidden;
}

@import "~theme/components/components";
@import "~theme/layouts/Layout";
@import "~theme/modules/modules";
@import "~theme/pages/pages";
@import "~theme/b2b";
